import gql from 'graphql-tag'

export const GET_VEEVA_USER_FOR_SOLICITATION = gql`
  query mGetVeevaUsers($veevaIds: [String!]!) {
    mGetVeevaUsers(veevaIds: $veevaIds) {
      veevaId
      email
      firstName
      lastName
      rppsNumber
      healthCareProfessional {
        healthCareProfessionalUuid
        healthCareOrganization
        emailAddress
        firstName
        lastName
        phoneNumber
        specialty
      }
    }
  }
`
