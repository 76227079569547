import gql from 'graphql-tag'

export const CREATE_SOLICITATION = gql`
  mutation createSolicitation(
    $solicitation: SolicitationInput!
    $hcp: SolicitationHealthCareProfessionalInput!
    $token: String
  ) {
    createSolicitation(solicitation: $solicitation, hcp: $hcp, token: $token) {
      status
    }
  }
`
