import gql from 'graphql-tag'

export const UPDATE_SOLICITATION = gql`
  mutation updateSolicitation(
    $hcp: SolicitationHealthCareProfessionalInput!
    $solicitation: UpdateSolicitationInput!
    $solicitationUuid: GUID!
  ) {
    updateSolicitation(hcp: $hcp, solicitation: $solicitation, solicitationUuid: $solicitationUuid) {
      solicitationUuid
    }
  }
`
