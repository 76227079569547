<template>
  <div class="Create_Solicitation" v-cy="$cy.congrex.solicitation.container">
    <v-overlay v-if="loading.hcp || (Object.keys(gathering).length === 0 && !hasSolicitation)">
      <v-progress-circular indeterminate />
    </v-overlay>

    <v-container v-if="hasSolicitation">
      <v-row justify="center" class="pt-12">
        <v-card elevation="3" max-width="500" class="pa-12 text-center">
          <v-card-title>{{ $t('createSolicitation.already-fill') }}</v-card-title>
          <img src="@/assets/valid-document.svg" />
        </v-card>
      </v-row>
    </v-container>

    <template v-else-if="hasSolicitation === false">
      <base-banner
        :imageUrl="require('@/assets/solicitation-background.jpg')"
        :title="gathering.name"
        :subTitle="gatheringDateTitle"
      />

      <v-container class="max-width pt-0">
        <v-row v-if="!isPublic && !isEdit" justify="center">
          <v-btn
            :href="`${$baseUrl}/formSolicitation.pdf`"
            color="primary"
            data-e2e="button-download-solicitation"
            target="_blank"
            depressed
            class="my-4 text-none"
          >
            {{ $t`download-pdf-form-solicitation` }}
          </v-btn>
        </v-row>

        <v-card
          class="mx-auto"
          :loading="$apollo.queries.hcp.loading"
          v-cy="$apollo.queries.hcp.loading ? null : $cy.congrex.solicitation.body"
        >
          <v-card-title class="pt-12 title justify-center">
            {{ $t`solicitation-title` }}
          </v-card-title>
          <v-card-text class="pa-6 px-12">
            <v-card-subtitle class="subtitle primary--text mx-auto">
              {{ $t`solicitation-informations-demandeur` }}
            </v-card-subtitle>
            <v-row>
              <v-col cols="12" md="10">
                <v-alert v-model="alertMessage" color="success" dismissible dark>
                  <span class="subtitle-2">{{ $t`alert-message-creation-solicitation` }}</span>
                </v-alert>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="hcp.lastName"
                  data-e2e="input-last-name"
                  :data-vv-as="$t`lastName`"
                  data-vv-name="lastName"
                  data-vv-validate-on="blur"
                  :error-messages="errors.collect('lastName')"
                  v-validate="'required'"
                  :label="`${$t`lastName`}*`"
                  key="lastName"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-cy="$cy.congrex.solicitation.email"
                  :data-vv-as="$t`email`"
                  data-vv-name="email"
                  data-vv-validate-on="blur"
                  :error-messages="errors.collect('email')"
                  v-validate="'required|email'"
                  v-model="hcp.emailAddress"
                  :label="`${$t`emailAddress`}*`"
                  key="email"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="hcp.firstName"
                  data-e2e="input-first-name"
                  :data-vv-as="$t`firstName`"
                  data-vv-name="firstName"
                  data-vv-validate-on="blur"
                  :error-messages="errors.collect('firstName')"
                  v-validate="'required'"
                  :label="`${$t`firstName`}*`"
                  key="firstName"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="solicitation.phoneNumber"
                  v-cy="$cy.congrex.solicitation.phone"
                  v-validate="'required|numeric'"
                  data-vv-name="phoneNumber"
                  :data-vv-as="$t`phoneNumber`"
                  :error-messages="errors.collect('phoneNumber')"
                  :label="`${$t`phoneNumber`}*`"
                  type="number"
                  key="phoneNumber"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  data-e2e="input-rpps-number"
                  :value="hcp.rppsNumber"
                  :label="`${$t`rppsNumber`}`"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="hcp.healthCareOrganization"
                  data-e2e="input-health-care-organization"
                  v-validate="'required'"
                  data-vv-name="healthCareOrganization"
                  :data-vv-as="$t`healthCareOrganization`"
                  data-vv-validate-on="blur"
                  :error-messages="errors.collect('healthCareOrganization')"
                  :label="`${$t`healthCareOrganization`}*`"
                  key="healthCareOrganization"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10">
                <v-select
                  v-model="hcp.specialty"
                  data-e2e="select-specialty"
                  v-validate="'required'"
                  :items="specialties"
                  data-vv-name="specialty"
                  :error-messages="errors.collect('specialty')"
                  dense
                  :label="`${$t`medical-specialty`}*`"
                  required
                  key="specialty"
                />
              </v-col>
            </v-row>
            <div class="error--text my-2">*{{ $t`required-fields` }}</div>
            <v-row>
              <v-col class="ma-0">
                {{ $t`solicitation-therapeutique-ask` }}
              </v-col>
            </v-row>
            <v-row>
              <v-col v-cy="$cy.congrex.solicitation.learnedSocietyMember">
                <v-radio-group
                  v-model="solicitation.isTherapeuticCommittee"
                  class="ma-0"
                  :data-vv-as="$t`yes-no`"
                  data-vv-name="isTherapeuticCommittee"
                  :error-messages="
                    errors.collect('isTherapeuticCommittee').length > 0
                      ? $t('createSolicitation.errorMessage.isTherapeuticCommittee')
                      : ''
                  "
                  ref="actualAndNewTherapeutic"
                  row
                  v-validate="'required'"
                  key="isTherapeuticCommittee"
                >
                  <v-radio
                    :value="true"
                    :label="$t`yes`"
                    v-cy="`${$cy.congrex.solicitation.learnedSocietyMember}/true`"
                  />
                  <v-radio
                    :value="false"
                    :label="$t`no`"
                    v-cy="`${$cy.congrex.solicitation.learnedSocietyMember}/false`"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="solicitation.isTherapeuticCommittee">
              <v-col cols="12" md="12">
                <p>
                  {{ $t`solicitationYesRoleFirstPart` }} <br />
                  {{ $t`solicitationYesRoleFirstTwo` }}
                </p>
                <v-textarea
                  v-model="solicitation.therapeuticCommitteeRole"
                  class="CreationSolicitation-textArea"
                  :class="{
                    'CreationSolicitation-textArea--error': errors.collect('therapeuticCommitteeRole').length > 0
                  }"
                  v-cy="$cy.congrex.solicitation.therapeuticCommitteeRole"
                  :data-vv-as="$t`therapeuticCommitteeRole`"
                  data-vv-name="therapeuticCommitteeRole"
                  :error-messages="
                    errors.collect('therapeuticCommitteeRole').length > 0
                      ? $t('createSolicitation.errorMessage.therapeuticCommitteeRole')
                      : ''
                  "
                  name="input-7-4"
                  solo
                  v-validate="'required_if:actualAndNewTherapeutic,true'"
                  key="therapeuticCommitteeRole"
                />
              </v-col>
            </v-row>
            <v-card-subtitle class="subtitle mx-auto">
              {{ $t`solicitation-informations-congres-evenement` }}
            </v-card-subtitle>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  data-e2e="input-event-name"
                  readonly
                  v-model="gathering.name"
                  :label="$t`gatheringName`"
                  key="gatheringName"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  data-e2e="input-event-city"
                  readonly
                  v-model="gathering.location"
                  :label="$t`gatheringPlace`"
                  key="gatheringPlace"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="5">
                <v-text-field data-e2e="input-event-date" readonly :value="gatheringDate" :label="$t`gatheringDates`" />
              </v-col>
              <v-col cols="12" class="d-flex flex-wrap align-baseline justify-space-between">
                <div :class="errorSupport ? 'error--text' : ''">
                  <div class="v-label">{{ $t`ask-support-type` }}</div>
                </div>
                <v-checkbox
                  v-validate="'required:true'"
                  v-model="solicitation.supportRegistration"
                  v-cy="$cy.congrex.solicitation.support.registration"
                  data-vv-name="supportRegistration"
                  :data-vv-as="$t`supportRegistration`"
                  dense
                  hide-details
                  :label="$t`inscriptionCongress`"
                  :error="errors.has('supportRegistration')"
                  key="supportRegistration"
                />
                <v-checkbox
                  v-model="solicitation.supportTransport"
                  v-cy="$cy.congrex.solicitation.support.transport"
                  dense
                  hide-details
                  :label="$t`transport`"
                  key="supportTransport"
                />
                <v-checkbox
                  v-model="solicitation.supportAccommodation"
                  v-cy="$cy.congrex.solicitation.support.accommodation"
                  dense
                  hide-details
                  :label="$t`accommodation`"
                  key="supportAccommodation"
                />
              </v-col>
              <v-col class="pt-0 mb-4" cols="12">
                <div v-if="errors.has('supportRegistration')" class="error--text" data-e2e="error-support-registration">
                  {{ $t('createSolicitation.errorMessage.support') }}
                </div>
              </v-col>
            </v-row>
            <div :class="errors.has('interest') && 'error--text'">
              <div>{{ $t`create-solicitation-select-interests` }}</div>
              <div v-if="errors.has('interest')" data-e2e="error-interests">
                {{ $t('createSolicitation.errorMessage.interest') }}
              </div>
            </div>
            <v-row>
              <v-col cols="12" md="5">
                <v-checkbox
                  v-model="solicitation.shareClinicalKnowledge"
                  v-cy="$cy.congrex.solicitation.interest.shareClinicalKnowledge"
                  :label="$t`shareClinicalKnowledge`"
                  key="shareClinicalKnowledge"
                />
                <v-checkbox
                  v-model="solicitation.newsSearchPath"
                  v-cy="$cy.congrex.solicitation.interest.newsSearchPath"
                  :label="$t`newsSearchPath`"
                  key="newsSearchPath"
                />
                <v-checkbox
                  v-model="solicitation.clinicalStudyPresentation"
                  v-cy="$cy.congrex.solicitation.interest.clinicalStudyPresentation"
                  :label="$t`clinicalStudyPresentation`"
                  key="clinicalStudyPresentation"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-checkbox
                  v-model="solicitation.actualAndNewTherapeutic"
                  v-cy="$cy.congrex.solicitation.interest.actualAndNewTherapeutic"
                  :label="$t`actualAndNewTherapeutic`"
                  key="actualAndNewTherapeutic"
                />
                <v-checkbox
                  v-model="solicitation.therapeuticArea"
                  v-cy="$cy.congrex.solicitation.interest.therapeuticArea"
                  :label="$t`therapeuticArea`"
                  key="therapeuticArea"
                />
                <p>
                  {{ $t`other` }}
                </p>
                <v-textarea
                  v-model="solicitation.others"
                  v-cy="$cy.congrex.solicitation.interest.others"
                  rows="3"
                  solo
                  name="input-7-4"
                  key="otherInterests"
                />
              </v-col>
            </v-row>

            <v-card-subtitle class="subtitle mx-auto">
              {{ $t`create-solicitation-accept-conditions` }}
            </v-card-subtitle>
            <v-row class="d-flex">
              <v-col cols="12" md="12">
                <v-checkbox
                  v-model="solicitation.noOtherFunding"
                  v-validate="'required:true'"
                  v-cy="$cy.congrex.solicitation.mandatory.noOtherFunding"
                  data-vv-name="clause1"
                  :error-messages="errors.collect('clause1')"
                  :label="$t`noOtherFunding`"
                  key="noOtherFunding"
                />

                <v-checkbox
                  v-if="
                    gathering.congress && gathering.congress.nationalityType === $const.nationalityType.INTERNATIONAL
                  "
                  v-model="solicitation.noOtherIntercontinentalFunding"
                  v-cy="$cy.congrex.solicitation.mandatory.noOtherIntercontinentalFunding"
                  v-validate="'required:true'"
                  data-vv-name="clause2"
                  :error-messages="errors.collect('clause2')"
                  key="noOtherIntercontinentalFunding"
                >
                  <template v-slot:label>
                    <p>
                      <span class="font-italic"> {{ $t`noOtherIntercontinentalFundingBlue` }}</span> <br />
                      {{ $t`noOtherIntercontinentalFunding` }}
                    </p>
                  </template>
                </v-checkbox>

                <v-checkbox
                  v-model="solicitation.noBiogenObligation"
                  v-cy="$cy.congrex.solicitation.mandatory.noBiogenObligation"
                  v-validate="'required:true'"
                  data-vv-name="clause3"
                  :error-messages="errors.collect('clause3')"
                  :label="$t`noBiogenObligation`"
                  key="noBiogenObligation"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <div v-if="isEdit" class="pb-10 text-center">
            <div class="d-flex justify-center">
              <base-preview-card v-model="initSolicitation.scan" @change="updateScan" canDownload canEdit />
            </div>
            <div class="mt-8">
              <v-btn
                class="text-none mr-3"
                color="error"
                depressed
                :to="{ name: 'SolicitationView', params: { solicitationUuid: initSolicitation.solicitationUuid } }"
              >
                {{ $t('updateSolicitation.cancelUpdate') }}
              </v-btn>
              <v-btn @click="updateSolicitation" class="text-none" color="primary" depressed :disabled="!changed">
                {{ $t('updateSolicitation.submitButton') }}
              </v-btn>
            </div>
            <v-row v-if="$validator.errors.items.length > 0" class="mt-5" justify="center">
              <v-col cols="10">
                <v-alert
                  v-for="error in $validator.errors.items.map(item => item.msg)"
                  :key="error"
                  color="error"
                  border="left"
                  dense
                  outlined
                >
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
          </div>
          <Consent
            v-else
            :disabled="!isValid"
            :value="solicitation.signature || solicitation.scan"
            :noScan="isPublic"
            solicitation
            @signature="v => $set(solicitation, 'signature', v)"
            @scan="v => $set(solicitation, 'scan', v)"
            @save="() => submit(isPublic ? 'confirmModalPublic' : 'confirmModal')"
          >
            <template v-slot:error>
              <v-row justify="center">
                <v-col cols="10">
                  <v-alert
                    v-for="error in $validator.errors.items.map(item => item.msg)"
                    :key="error"
                    color="error"
                    border="left"
                    dense
                    outlined
                  >
                    {{ error }}
                  </v-alert>
                </v-col>
              </v-row>
            </template>
            <template v-slot:footer>
              <p class="mt-3 grey--text">{{ $t('complianceValue') }}</p>
            </template>
          </Consent>
        </v-card>
      </v-container>
    </template>

    <base-confirm-modal
      @confirm="createSolicitation"
      v-model="confirmModalPublic"
      :title="$t('createSolicitation.by-hcp.confirm-modal-message', { eventName: gathering.name })"
      :disableValidate="disableValidateSolicitation"
      :loaded="createdSolicitation"
    />

    <base-confirm-modal
      @confirm="createSolicitation"
      v-model="confirmModal"
      :title="$t('createSolicitation.by-biogen.confirm-modal-message', { eventName: gathering.name })"
      :disableValidate="disableValidateSolicitation"
      :loaded="createdSolicitation"
    />

    <v-dialog v-model="confirmed.display" max-width="400px" persistent>
      <v-card class="py-8 px-6">
        <div class="text-center">
          <div class="body-1">{{ confirmed.text }}</div>
          <v-icon class="d-block mt-8" color="success" size="100">mdi-check-circle-outline</v-icon>
          <v-btn
            v-if="!isPublic"
            class="mt-9 text-none"
            color="success"
            v-cy="$cy.congrex.solicitation.successModal.confirm"
            outlined
            :to="confirmedModalTo"
          >
            {{ confirmed.backButtonName }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { mapGetters, mapMutations } from 'vuex'

import CONST from '@/const/shared'
import Consent from '@/components/serf/Consent'
import { GET_GATHERING_CONGRESS_TO_CREATE_SOLICITATION } from '@/graphql/Gatherings/GetGathering'
import { HAS_SOLICITATION } from '@/graphql/Solicitation/HasSolicitation'
import { GET_HCP_SOLICITATION } from '@/graphql/Hcp/GetHcp'
import { GET_VEEVA_USER_FOR_SOLICITATION } from '@/graphql/VeevaUser/MGetVeevaUser'
import { CREATE_SOLICITATION } from '@/graphql/Solicitation/CreateSolicitation'
import { UPDATE_SOLICITATION } from '@/graphql/Solicitation/UpdateSolicitation'

export default {
  name: 'Create_Solicitation',

  $_veeValidate: {
    validator: 'new'
  },

  props: {
    changed: {
      type: Boolean,
      default: false
    },
    initSolicitation: {
      type: Object,
      default: () => ({})
    }
  },

  components: { Consent },

  data() {
    let phoneNumber = this.$get(this.initSolicitation, 'phoneNumber', '')

    if (phoneNumber.includes('+33')) {
      phoneNumber = phoneNumber.replace('+33', '0')
    }

    return {
      alertMessage: true,
      createdSolicitation: false,
      confirmed: {
        backButtonName: this.$t('createSolicitation.modal-confirmed.back-button-name'),
        display: false,
        text: ''
      },
      confirmModal: false,
      confirmModalPublic: false,
      confirmedSolicitation: false,
      dialogResult: {},
      disableValidateSolicitation: false,
      gathering: {},
      hasSolicitation: null,
      hcp: {
        emailAddress: this.$get(this.initSolicitation, 'healthCareProfessional.emailAddress', ''),
        firstName: this.$get(this.initSolicitation, 'healthCareProfessional.firstName', ''),
        healthCareOrganization: this.$get(this.initSolicitation, 'healthCareProfessional.healthCareOrganization', ''),
        healthCareProfessionalUuid: this.$get(
          this.initSolicitation,
          'healthCareProfessional.healthCareProfessionalUuid',
          ''
        ),
        lastName: this.$get(this.initSolicitation, 'healthCareProfessional.lastName', ''),
        rppsNumber: this.$get(this.initSolicitation, 'healthCareProfessional.rppsNumber', ''),
        specialty: this.$get(this.initSolicitation, 'healthCareProfessional.specialty', ''),
        targetBbu: this.$get(this.initSolicitation, 'healthCareProfessional.targetBbu', ''),
        targetMs: this.$get(this.initSolicitation, 'healthCareProfessional.targetMs', ''),
        targetSma: this.$get(this.initSolicitation, 'healthCareProfessional.targetSma', ''),
        veevaId: this.$get(this.initSolicitation, 'healthCareProfessional.veevaId', '')
      },
      isValid: false,
      loading: {
        hcp: false
      },
      menu: false,
      modalConfirmCreate: false,
      solicitation: {
        actualAndNewTherapeutic: this.initSolicitation.actualAndNewTherapeutic || false,
        businessUnitUuid: this.businessUnitUuid,
        clinicalStudyPresentation: this.initSolicitation.clinicalStudyPresentation || false,
        isTherapeuticCommittee:
          this.initSolicitation.isTherapeuticCommittee !== undefined
            ? this.initSolicitation.isTherapeuticCommittee
            : null,
        newsSearchPath: this.initSolicitation.newsSearchPath || false,
        noBiogenObligation: this.initSolicitation.noBiogenObligation || false,
        noOtherFunding: this.initSolicitation.noOtherFunding || false,
        noOtherIntercontinentalFunding: this.initSolicitation.noOtherIntercontinentalFunding || false,
        others: this.initSolicitation.others || '',
        phoneNumber,
        shareClinicalKnowledge: this.initSolicitation.shareClinicalKnowledge || false,
        supportAccommodation: this.initSolicitation.supportAccommodation || false,
        supportRegistration: this.initSolicitation.supportRegistration || false,
        supportTransport: this.initSolicitation.supportTransport || false,
        therapeuticArea: this.initSolicitation.therapeuticArea || false,
        therapeuticCommitteeRole: this.initSolicitation.therapeuticCommitteeRole || ''
      },
      statusSelected: '',
      therapeuticCommittee: this.initSolicitation.therapeuticCommittee || ''
    }
  },

  computed: {
    ...mapGetters(['userBusinessUnitUuid']),
    businessUnitUuid() {
      return this.userBusinessUnitUuid || this.$route.query.businessUnitUuid
    },
    confirmedModalTo() {
      if (this.isEdit) {
        return { name: 'SolicitationView', params: { solicitationUuid: this.initSolicitation.solicitationUuid } }
      }

      return { name: 'CongressTabs', params: { gatheringUuid: this.$route.query.gatheringUuid } }
    },
    errors() {
      return this.$validator.errors.items.map(item => item.msg)
    },
    errorSupport() {
      return this.$validator.errors.items.some(item => item.field === 'supportRegistration')
    },
    fullName() {
      return `${this.hcp.firstName} ${this.hcp.lastName}`
    },
    gatheringDate() {
      return `${moment(this.gathering.beginDate).calendar()} - ${moment(this.gathering.endDate).calendar()}`
    },
    gatheringDateTitle() {
      return `Du ${moment(this.gathering.beginDate).format('DD')} au ${moment(this.gathering.endDate).format(
        'DD MMMM	YYYY'
      )}`
    },
    isEdit() {
      return Object.keys(this.initSolicitation).length > 0
    },
    isPublic() {
      return Boolean(this.$route.meta.public)
    },
    interestError() {
      const hasInterest = [
        this.solicitation.shareClinicalKnowledge,
        this.solicitation.newsSearchPath,
        this.solicitation.clinicalStudyPresentation,
        this.solicitation.clinicalStudyPresentation,
        this.solicitation.actualAndNewTherapeutic,
        this.solicitation.therapeuticArea
      ].some(Boolean)

      const hasOtherInterest = typeof this.solicitation.others === 'string' && this.solicitation.others.length > 0

      return !hasInterest && !hasOtherInterest
    },
    specialties() {
      return _sortBy(
        this.$const.specialties.map(specialty => ({
          text: this.$t(`specialtyValues.${specialty}`),
          value: specialty
        })),
        ['text']
      )
    },
    status() {
      return [
        { text: this.$t('solicitation-status.validated'), value: this.$const.solicitationStatus.SENT },
        { text: this.$t('solicitation-status.ad-hoc'), value: this.$const.solicitationStatus.AD_HOC_VALIDATION }
      ]
    }
  },

  async created() {
    if (this.isPublic) {
      this.$store.commit('RESET_STORE')
      const {
        data: { hasSolicitation }
      } = await this.$apollo.mutate({ mutation: HAS_SOLICITATION, variables: { token: this.$route.query.token } })
      this.hasSolicitation = hasSolicitation
    } else {
      this.hasSolicitation = false
    }
    this.statusSelected = this.status[0].value
  },

  mounted() {
    const dict = {
      custom: {
        clause1: {
          required: this.$t('error-message-clause-required', { value: 1 })
        },
        clause2: {
          required: this.$t('error-message-clause-required', { value: 2 })
        },
        clause3: {
          required: this.$t('error-message-clause-required', { value: 3 })
        }
      }
    }
    this.$validator.localize('fr', dict)
  },

  apollo: {
    hcp: {
      query() {
        return this.isPublic ? GET_HCP_SOLICITATION : GET_VEEVA_USER_FOR_SOLICITATION
      },
      skip() {
        const haveToSkip = (this.isPublic && this.hasSolicitation === null) || this.hasSolicitation || this.isEdit

        if (haveToSkip) {
          this.loading.hcp = false
        }

        return haveToSkip
      },
      variables() {
        this.loading.hcp = true
        return this.isPublic
          ? { token: this.$route.query.token }
          : { veevaIds: [this.$route.query.veevaId || this.$route.params.veevaId] }
      },
      update(data) {
        this.loading.hcp = false

        this.$validator.pause()
        this.$nextTick(() => {
          this.$validator.errors.clear()
          this.$validator.fields.items.forEach(field => field.reset())
          this.$validator.fields.items.forEach(field => this.errors.remove(field))
          this.$validator.resume()
        })

        if (!this.isPublic) {
          const user = data.mGetVeevaUsers[0]
          const healthCareProfessionalUuid = _get(user, 'healthCareProfessional.healthCareProfessionalUuid')

          const hcp = {
            emailAddress: _get(user, 'healthCareProfessional.email', user.email),
            firstName: _get(user, 'healthCareProfessional.firstName', user.firstName),
            healthCareOrganization: _get(user, 'healthCareProfessional.healthCareOrganization', ''),
            lastName: _get(user, 'healthCareProfessional.lastName', user.lastName),
            rppsNumber: user.rppsNumber,
            specialty: _get(user, 'healthCareProfessional.specialty', ''),
            veevaId: user.veevaId
          }

          if (healthCareProfessionalUuid) {
            hcp.healthCareProfessionalUuid = healthCareProfessionalUuid
          }

          return hcp
        }

        const user = data.healthCareProfessional
        return {
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          healthCareProfessionalUuid: user.healthCareProfessionalUuid,
          healthCareOrganization: user.healthCareOrganization,
          lastName: user.lastName,
          rppsNumber: user.rppsNumber,
          specialty: user.specialty,
          veevaId: user.veevaId
        }
      },
      result({ data }) {
        let phoneNumber =
          this.solicitation.phoneNumber ||
          this.$get(data, 'mGetVeevaUsers[0].healthCareProfessional.phoneNumber') ||
          this.$get(data, 'healthCareProfessional.phoneNumber', '')

        if (phoneNumber.includes('+33')) {
          phoneNumber = phoneNumber.replace('+33', '0')
        }

        this.solicitation.phoneNumber = phoneNumber
      }
    },
    gathering: {
      query: GET_GATHERING_CONGRESS_TO_CREATE_SOLICITATION,
      variables() {
        return this.isPublic
          ? { token: this.$route.query.token }
          : {
              gatheringUuid: this.$route.query.gatheringUuid || this.$route.params.gatheringUuid
            }
      },
      skip() {
        return (this.isPublic && this.hasSolicitation === null) || this.hasSolicitation
      },
      result({ data: { gathering } }) {
        this.solicitation.congressUuid = gathering.congress.congressUuid
      }
    }
  },

  watch: {
    hcp: {
      handler() {
        this.$emit('change', true)
      },
      deep: true
    },
    solicitation: {
      handler() {
        this.$emit('change', true)
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations({
      setModalMessage: 'SET_MODAL_MESSAGE'
    }),
    async createSolicitation() {
      const variables = { hcp: this.hcp, solicitation: this.solicitation }
      this.disableValidateSolicitation = true
      this.createdSolicitation = false

      if (this.isPublic) {
        variables.token = this.$route.query.token
      }

      if (this.$route.query.businessUnitUuid) {
        variables.solicitation.businessUnitUuid = this.$route.query.businessUnitUuid
      }

      if (!this.solicitation.scan) {
        delete variables.solicitation.scan
      } else if (!this.solicitation.signature) {
        delete variables.solicitation.signature
      }

      if (!this.solicitation.emailAddress) {
        variables.solicitation.emailAddress = this.hcp.emailAddress
      }

      try {
        const {
          data: { createSolicitation }
        } = await this.$apollo.mutate({
          mutation: CREATE_SOLICITATION,
          variables
        })

        this.disableValidateSolicitation = false
        this.createdSolicitation = true

        this.confirmModal = false
        this.confirmed.text =
          createSolicitation.status === CONST.solicitationStatus.SENT
            ? this.$t`validated-solicitation`
            : this.$t`sended-solicitation`
        this.confirmed.display = true
      } catch (error) {
        console.error("l'enregistrement a échoué : ", error)
        this.disableValidateSolicitation = false
        this.createdSolicitation = false
      }
    },
    formatHcpSolicitationToUpdate(hcp) {
      const result = {}
      const hcpKeys = ['emailAddress', 'firstName', 'healthCareProfessionalUuid', 'healthCareOrganization', 'lastName', 'specialty', 'veevaId']

      Object.keys(hcp).forEach(key => {
        if (hcpKeys.includes(key) && hcp[key] !== null) {
          result[key] = hcp[key]
        }
      })

      result.phoneNumber = this.solicitation.phoneNumber

      return result
    },
    formatSolicitationToUpdate(solicitation) {
      const result = {}
      const solicitationKeys = [
        'actualAndNewTherapeutic',
        'clinicalStudyPresentation',
        'isTherapeuticCommittee',
        'newsSearchPath',
        'noBiogenObligation',
        'noOtherFunding',
        'noOtherIntercontinentalFunding',
        'others',
        'phoneNumber',
        'scan',
        'shareClinicalKnowledge',
        'supportAccommodation',
        'supportRegistration',
        'supportTransport',
        'therapeuticArea',
        'therapeuticCommitteeRole'
      ]

      Object.keys(solicitation).forEach(key => {
        if (solicitationKeys.includes(key) && solicitation[key] !== null) {
          result[key] = solicitation[key]
        }
      })

      if (!solicitation.isTherapeuticCommittee) {
        result.therapeuticCommitteeRole = ''
      }

      result.emailAddress = this.hcp.emailAddress

      return result
    },
    updateScan(scan) {
      this.$emit('change', true)
      this.solicitation.scan = scan
    },
    async updateSolicitation() {
      await this.validate()

      if (!this.isValid) return

      const variables = {
        hcp: { ...this.formatHcpSolicitationToUpdate(this.hcp) },
        solicitation: {
          ...this.formatSolicitationToUpdate(this.solicitation)
        },
        solicitationUuid: this.initSolicitation.solicitationUuid
      }

      await this.$apollo.mutate({
        mutation: UPDATE_SOLICITATION,
        variables
      })

      this.$emit('change', false)
      this.confirmed.text = this.$t('updateSolicitation.updateConfirmedMessage')
      this.confirmed.backButtonName = this.$t('updateSolicitation.buttonBackSolicitationView')
      this.confirmed.display = true
    },
    async submit(openModal) {
      await this.validate()

      if (this.isValid) {
        this[openModal] = true
      }
    },
    async validate() {
      const signError = {
        field: 'sign',
        msg: this.$t`error-message-sign-required`
      }

      if (this.interestError && !this.$validator.errors.has('interest')) {
        this.$validator.errors.add({ field: 'interest', msg: this.$t('createSolicitation.errorMessage.interest') })
      } else if (!this.interestError) {
        this.$validator.errors.remove('interest')
      }

      if (!this.isEdit && !this.solicitation.signature && !this.solicitation.scan) {
        if (!this.$validator.errors.has(signError.field)) {
          this.$validator.errors.add(signError)
        }

        this.isValid = false
        await this.$validator.validateAll()
      } else {
        this.$validator.errors.remove(signError.field)
        this.isValid = (await this.$validator.validateAll()) && this.$validator.errors.items.length === 0
      }
    }
  }
}
</script>

<style lang="scss">
.Create_Solicitation {
  .v-label {
    height: auto;
  }

  .subtitle {
    background-color: rgba(27, 154, 247, 0.1);
    font-size: 20px;
    font-weight: 500;
  }
  .title {
    font-size: 24px !important;
    font-weight: bold;
    text-align: center;
  }
  .fluid-bandeau {
    text-align: center;
    padding: 25px 0;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .max-width {
    max-width: 1100px;
  }
}

.CreationSolicitation-textArea--error .v-input__slot {
  border: 1px solid red;
}
</style>
