import gql from 'graphql-tag'

import { FULL_SERF } from '../Serf/Serf'

export const GET_HCP = gql`
  query serfsHcp($healthCareProfessionalUuid: GUID!) {
    healthCareProfessional(healthCareProfessionalUuid: $healthCareProfessionalUuid) {
      firstName
      lastName
      postalCode
      city
      archives {
        previewUrl
        downloadUrl
        date
        status
        statusDate
        type
      }
      scientificExchangeRequestForm {
        ...AllSerfTopics
        scientificExchangeRequestFormUuid
        signatureDate
        updatedAt
        createdAt
      }
    }
  }
  ${FULL_SERF}
`

export const GET_HCP_SOLICITATION = gql`
  query getHcp($healthCareProfessionalUuid: GUID, $token: String) {
    healthCareProfessional(healthCareProfessionalUuid: $healthCareProfessionalUuid, token: $token) {
      healthCareProfessionalUuid
      healthCareOrganization
      emailAddress
      firstName
      lastName
      phoneNumber
      rppsNumber
      specialty
      veevaId
    }
  }
`

export const GET_HCP_FOR_HISTORICAL = gql`
  query getHcp($hcpUuid: GUID) {
    healthCareProfessional(healthCareProfessionalUuid: $hcpUuid) {
      firstName
      lastName
      specialty
      veevaId
      emailAddress
      city
      healthCareOrganization
      solicitations {
        phoneNumber
      }
    }
  }
`

export const GET_HCP_HISTORICAL = gql`
  query getHcpHistorical($hcpUuid: GUID, $filterDate: DateTime, $getAfter: Boolean, $gatheringTypes: [String]) {
    healthCareProfessional(healthCareProfessionalUuid: $hcpUuid) {
      eventParticipations(filterDate: $filterDate, getAfter: $getAfter, gatheringTypes: $gatheringTypes) {
        eveniumStatus
        gathering {
          gatheringUuid
          city
          gatheringType
          name
          beginDate
          endDate
        }
        gatheringDates {
          gatheringUuid
        }
        solicitation {
          solicitationUuid
        }
        solicitationStatus
        eveniumEvent {
          contactStatus
          id
          status
        }
      }
    }
  }
`
